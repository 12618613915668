import React, { useState, useEffect, useCallback } from "react";

export const availableModels = ["cat", "chair", "house", "sailboat"]

export const massagePrediction = (predictions) =>
    predictions.length == 0 ? [{confidence: 0.0}]
    : predictions.filter(p => availableModels.includes(p.label))

const label2Chat = (label: string) => {
    label.replace('_', ' ')
}

const aOrAn = (noun: string) => !['a', 'e', 'i', 'o', 'u'].map(v => noun.startsWith(v)).reduce((a,b) => a && b) ? 'a' : 'an'

export const ModelChat = ({ predictions }) => 
    <div style={{
        position: 'absolute',
        top: '800px',
        left: '750px',
        width: '256px',
        zIndex: 90,
        background: 'black',
        color: 'white',
        padding: '20px',
        textAlign: 'center'
      }}>
       {predictions.length == 0 ? "Draw something in the squiggle pad!" : 
         predictions[0].confidence < 0.05 ? "I'm not sure what that is..."
        : `I think that's ${aOrAn(predictions[0].label)} ${predictions[0].label}`}
    </div>
