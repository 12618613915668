import { useState, useEffect, useCallback } from "react";
import { load } from "laplacian-deformation";
import { BufferGeometry, BufferAttribute, Vector3, Matrix4 } from "three";

interface GeometryDeform {
  selectVerts(moved: number[], moveable: number[]): void;
  moveVerts(delta: Vector3, toScreenSpaceMatrix: Matrix4): void;
}

export function useLapDeform(geometry: BufferGeometry): GeometryDeform {
  let [mod, setModule] = useState<any>();
  useEffect(() => {
    load((initModule, prepareDeform, doDeform, freeModule) => {
      setModule([initModule, prepareDeform, doDeform, freeModule]);
    });
  }, []);

  let [innerMesh, setInnerMesh] = useState<any>();
  useEffect(() => {
    if (mod == undefined) return;
    let [initModule] = mod;
    let positions: number[][] = [];
    let posAttr = geometry.getAttribute("position") as BufferAttribute;
    for (let i = 0, c = posAttr.count; i < c; i++) {
      positions.push([posAttr.getX(i), posAttr.getY(i), posAttr.getZ(i)]);
    }

    let maxPos = positions.reduce(([x, y, z], [xp, yp, zp]) => [
      Math.max(x, xp),
      Math.max(y, yp),
      Math.max(z, zp),
    ]);

    let minPos = positions.reduce(([x, y, z], [xp, yp, zp]) => [
      Math.min(x, xp),
      Math.min(y, yp),
      Math.min(z, zp),
    ]);

    let scale = maxPos
      .map((p, i) => p - minPos[i])
      .reduce((a, b) => Math.max(a, b));

    console.log(scale);

    positions = positions.map(([x, y, z]) => [
      (x - minPos[0]) / scale,
      (y - minPos[1]) / scale,
      (z - minPos[2]) / scale,
    ]);

    let index = geometry.index;
    if (index == null) {
      throw new Error("Geometry must be indexed");
    }

    let cells: number[][] = [];
    for (let i = 0, c = index.count; i < c; i += 3) {
      cells.push([index.getX(i), index.getX(i + 1), index.getX(i + 2)]);
    }
    setInnerMesh({ positions, cells, scale, offset: new Vector3(...minPos) });
    initModule({ positions, cells });
  }, [geometry, mod]);

  const [selectedPos, setSelectedPos] =
    useState<{
      pos: number[][];
      nToMove: number;
    }>();

  const selectVerts = useCallback(
    (moved: number[], moveable: number[]) => {
      if (mod == undefined) return;
      let [_, prepareDeform] = mod;
      prepareDeform(moved, moveable);
      setSelectedPos({
        pos: moved.concat(moveable).map((i) => innerMesh.positions[i]),
        nToMove: moved.length,
      });
    },
    [mod, innerMesh]
  );

  const moveVerts = useCallback(
    (delta: Vector3, toScreenSpaceMatrix: Matrix4) => {
      if (mod == undefined || selectedPos == undefined) return;
      let iv = toScreenSpaceMatrix.clone().invert();

      let [, , doDeform] = mod;
      let targetPos = selectedPos.pos.map(([x, y, z], i) => {
        if (i < selectedPos.nToMove) {
          let v = new Vector3(x, y, z)
            .multiplyScalar(innerMesh.scale)
            .add(innerMesh.offset);

          v.applyMatrix4(toScreenSpaceMatrix).add(delta);

          v.applyMatrix4(iv)
            .sub(innerMesh.offset)
            .multiplyScalar(1.0 / innerMesh.scale);
          return [v.x, v.y, v.z];
        } else {
          return [x, y, z];
        }
      });

      setSelectedPos({ pos: targetPos, nToMove: selectedPos.nToMove });


      let newPos = doDeform(targetPos);
      let posAttr = geometry.getAttribute("position") as BufferAttribute;
      for (let i = 0, c = posAttr.count; i < c; i++) {
        let [x, y, z] = newPos[i];
        let v = new Vector3(x, y, z)
          .multiplyScalar(innerMesh.scale)
          .add(innerMesh.offset);

        posAttr.setXYZ(i, v.x, v.y, v.z);
      }
      posAttr.needsUpdate = true;
      geometry.setAttribute("position", posAttr);
    },
    [mod, geometry, innerMesh, selectedPos]
  );

  return { selectVerts, moveVerts };
}

export function useSimpleDeform(geometry: BufferGeometry): GeometryDeform {
  const [selection, setSelection] = useState({
    vidxs: [] as number[],
    α: [] as number[],
  });

  const selectVerts = useCallback(
    ((vidxs, α) => {
      setSelection({ vidxs, α });
    }) as GeometryDeform["selectVerts"],
    [geometry]
  );

  const moveVerts = useCallback(
    ((Δ: Vector3, toScreenSpaceMatrix: Matrix4) => {
      let iv = toScreenSpaceMatrix.clone().invert();
      let pos = geometry.getAttribute("position");
      for (let i = 0, l = selection.vidxs.length; i < l; i++) {
        let iₓ = selection.vidxs[i];
        let x = new Vector3(pos.getX(iₓ), pos.getY(iₓ), pos.getZ(iₓ));
        x.applyMatrix4(toScreenSpaceMatrix).add(
          Δ.clone().multiplyScalar(selection.α[i])
        );
        x.applyMatrix4(iv);
        pos.setXYZ(iₓ, x.x, x.y, x.z);
      }
      pos.needsUpdate = true;
      geometry.setAttribute("position", pos);
    }) as GeometryDeform["moveVerts"],
    [geometry, selection]
  );

  return { selectVerts, moveVerts };
}
