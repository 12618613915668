import { Vector3, Line3, Line } from "three";
import {
  MeshBVHVisualizer,
  MeshBVH,
  CONTAINED,
  INTERSECTED,
  NOT_INTERSECTED,
} from "three-mesh-bvh";

export function lassoMesh(
  mesh,
  bvh,
  lasso,
  toScreenSpaceMatrix
) {
  const boxPoints = new Array(8).fill(undefined).map(() => new Vector3());
  const boxLines = new Array(12).fill(undefined).map(() => new Line3());
  let lassoSegments: Line[] = [];
  let perBoundsSegments: Line[] = [];
  let indices: number[] = [];
  let partial: number[] = [];

  // create scratch points and lines to use for selection
  while (lassoSegments.length < lasso.length) {
    lassoSegments.push(new Line3());
  }

  lassoSegments.length = lasso.length;

  for (let s = 0, l = lasso.length; s < l; s += 1) {
    const line = lassoSegments[s];
    const sNext = (s + 1) % l;
    line.start.x = lasso[s][0];
    line.start.y = lasso[s][1];

    line.end.x = lasso[sNext][0];
    line.end.y = lasso[sNext][1];
  }

  bvh.shapecast(
    mesh,
    (box, isLeaf, score, depth) => {
      // Get the bounding box points
      const { min, max } = box;
      let index = 0;

      let minY = Infinity;
      let maxY = -Infinity;
      let minX = Infinity;
      for (let x = 0; x <= 1; x++) {
        for (let y = 0; y <= 1; y++) {
          for (let z = 0; z <= 1; z++) {
            const v = boxPoints[index];
            v.x = x === 0 ? min.x : max.x;
            v.y = y === 0 ? min.y : max.y;
            v.z = z === 0 ? min.z : max.z;
            v.w = 1;
            v.applyMatrix4(toScreenSpaceMatrix);
            index++;

            if (v.y < minY) minY = v.y;
            if (v.y > maxY) maxY = v.y;
            if (v.x < minX) minX = v.x;
          }
        }
      }

      // Find all the relevant segments here and cache them in the above array for
      // subsequent child checks to use.
      let parentSegments = perBoundsSegments[depth - 1] || lassoSegments;
      let segmentsToCheck = perBoundsSegments[depth] || [];
      segmentsToCheck.length = 0;
      perBoundsSegments[depth] = segmentsToCheck;
      for (let i = 0, l = parentSegments.length; i < l; i++) {
        const line = parentSegments[i];
        const sx = line.start.x;
        const sy = line.start.y;
        const ex = line.end.x;
        const ey = line.end.y;
        if (sx < minX && ex < minX) continue;

        const startAbove = sy > maxY;
        const endAbove = ey > maxY;
        if (startAbove && endAbove) continue;

        const startBelow = sy < minY;
        const endBelow = ey < minY;
        if (startBelow && endBelow) continue;

        segmentsToCheck.push(line);
      }

      if (segmentsToCheck.length === 0) {
        return NOT_INTERSECTED;
      }

      // Get the screen space hull lines
      const hull = getConvexHull(boxPoints) ?? [];
      const lines = hull.map((p, i) => {
        const nextP = hull[(i + 1) % hull.length];
        const line = boxLines[i];
        line.start.copy(p);
        line.end.copy(nextP);
        return line;
      });

      // If a lasso point is inside the hull then it's intersected and cannot be contained
      if (pointRayCrossesSegments(segmentsToCheck[0].start, lines) % 2 === 1) {
        return INTERSECTED;
      }

      // check if the screen space hull is in the lasso
      let crossings = 0;
      for (let i = 0, l = hull.length; i < l; i++) {
        const v = hull[i];
        const pCrossings = pointRayCrossesSegments(v, segmentsToCheck);

        if (i === 0) {
          crossings = pCrossings;
        }

        // if two points on the hull have different amounts of crossings then
        // it can only be intersected
        if (crossings !== pCrossings) {
          return INTERSECTED;
        }
      }

      // check if there are any intersections
      for (let i = 0, l = lines.length; i < l; i++) {
        const boxLine = lines[i];
        for (let s = 0, ls = segmentsToCheck.length; s < ls; s++) {
          if (lineCrossesLine(boxLine, segmentsToCheck[s])) {
            return INTERSECTED;
          }
        }
      }

      return crossings % 2 === 0 ? NOT_INTERSECTED : CONTAINED;
    },
    (tri, a: number, b: number, c: number, contained, depth) => {
      // if the parent bounds were marked as contained
      if (contained) {
        indices.push(a, b, c);
        return false;
      }

      // check all the segments if using no bounds tree
      const segmentsToCheck = perBoundsSegments[depth];
      // if (params.selectionMode === 'centroid') {

      // get the center of the triangle
      const centroid = tri.a
        .add(tri.b)
        .add(tri.c)
        .multiplyScalar(1 / 3);
      centroid.applyMatrix4(toScreenSpaceMatrix);

      //   counting the crossings
      // const crossings = pointRayCrossesSegments(centroid, segmentsToCheck);
      // if (crossings % 2 === 1) {
      //   indices.push(a, b, c);
      //   return false;
      // }

      // } else if (params.selectionMode === 'intersection') {

      // get the projected vertices
      const vertices = [
        [tri.a, a],
        [tri.b, b],
        [tri.c, c],
      ];

      for (let j = 0; j < 3; j++) {
        const [v, vidx] = vertices[j];
        v.applyMatrix4(toScreenSpaceMatrix);

        const crossings = pointRayCrossesSegments(v, segmentsToCheck);
        if (crossings % 2 === 1) {
          partial.push(vidx);
        }
      }

      // get the lines for the triangle
      const lines = [boxLines[0], boxLines[1], boxLines[2]];

      lines[0].start.copy(tri.a);
      lines[0].end.copy(tri.b);

      lines[1].start.copy(tri.b);
      lines[1].end.copy(tri.c);

      lines[2].start.copy(tri.c);
      lines[2].end.copy(tri.a);

      for (let i = 0; i < 3; i++) {
        const l = lines[i];
        for (let s = 0, sl = segmentsToCheck.length; s < sl; s++) {
          if (lineCrossesLine(l, segmentsToCheck[s])) {
            partial.push(a, b, c);
            return false;
          }
        }
      }

      //   }

      return false;
    }
  );

  let index = mesh.geometry.index
  let indices$ = new Set(indices.map(i => index.getX(i) as number))

  let partial$ = new Set(partial.map(i => index.getX(i) as number))

  return {indices, partial, indices$, partial$};
}

// Math Functions
// https://www.geeksforgeeks.org/convex-hull-set-2-graham-scan/
function getConvexHull(points: Vector3[]): Vector3[] | undefined {
  function orientation(p, q, r) {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);

    if (val == 0) {
      return 0; // colinear
    }

    // clock or counterclock wise
    return val > 0 ? 1 : 2;
  }

  function distSq(p1, p2) {
    return (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y);
  }

  function compare(p1, p2) {
    // Find orientation
    const o = orientation(p0, p1, p2);
    if (o == 0) return distSq(p0, p2) >= distSq(p0, p1) ? -1 : 1;

    return o == 2 ? -1 : 1;
  }

  // find the lowest point in 2d
  let lowestY = Infinity;
  let lowestIndex = -1;
  for (let i = 0, l = points.length; i < l; i++) {
    const p = points[i];
    if (p.y < lowestY) {
      lowestIndex = i;
      lowestY = p.y;
    }
  }

  // sort the points
  const p0 = points[lowestIndex];
  points[lowestIndex] = points[0];
  points[0] = p0;

  points = points.sort(compare);

  // filter the points
  let m = 1;
  const n = points.length;
  for (let i = 1; i < n; i++) {
    while (i < n - 1 && orientation(p0, points[i], points[i + 1]) == 0) {
      i++;
    }

    points[m] = points[i];
    m++;
  }

  // early out if we don't have enough points for a hull
  if (m < 3) {
    return undefined;
  }

  // generate the hull
  const hull = [points[0], points[1], points[2]];
  for (let i = 3; i < m; i++) {
    while (
      orientation(hull[hull.length - 2], hull[hull.length - 1], points[i]) !== 2
    ) {
      hull.pop();
    }

    hull.push(points[i]);
  }

  return hull;
}

function pointRayCrossesLine(point, line, prevDirection, thisDirection) {
  const { start, end } = line;
  const px = point.x;
  const py = point.y;

  const sy = start.y;
  const ey = end.y;

  if (sy === ey) return false;

  if (py > sy && py > ey) return false; // above
  if (py < sy && py < ey) return false; // below

  const sx = start.x;
  const ex = end.x;
  if (px > sx && px > ex) return false; // right
  if (px < sx && px < ex) {
    // left

    if (py === sy && prevDirection !== thisDirection) {
      return false;
    }

    return true;
  }

  // check the side
  const dx = ex - sx;
  const dy = ey - sy;
  const perpx = dy;
  const perpy = -dx;

  const pdx = px - sx;
  const pdy = py - sy;

  const dot = perpx * pdx + perpy * pdy;

  if (Math.sign(dot) !== Math.sign(perpx)) {
    return true;
  }

  return false;
}

function pointRayCrossesSegments(point, segments) {
  let crossings = 0;
  const firstSeg = segments[segments.length - 1];
  if (firstSeg == undefined) debugger;
  let prevDirection = firstSeg.start.y > firstSeg.end.y;
  for (let s = 0, l = segments.length; s < l; s++) {
    const line = segments[s];
    const thisDirection = line.start.y > line.end.y;
    if (pointRayCrossesLine(point, line, prevDirection, thisDirection)) {
      crossings++;
    }

    prevDirection = thisDirection;
  }

  return crossings;
}

// https://stackoverflow.com/questions/3838329/how-can-i-check-if-two-segments-intersect
function lineCrossesLine(l1, l2) {
  function ccw(A, B, C) {
    return (C.y - A.y) * (B.x - A.x) > (B.y - A.y) * (C.x - A.x);
  }

  const A = l1.start;
  const B = l1.end;

  const C = l2.start;
  const D = l2.end;

  return ccw(A, C, D) !== ccw(B, C, D) && ccw(A, B, C) !== ccw(A, B, D);
}
