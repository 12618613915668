import React, { useState, useEffect, useCallback } from "react";

export function DraggableWindow({
  top,
  left,
  inner,
  title
}: {
  top: number;
  left: number;
  inner: JSX.Element;
  title: JSX.Element;
}) {
  let [pos, setPos] = useState({ top: 0, left: 0 });
  useEffect(() => {
    setPos({ top, left });
  }, []);

  let [dragging, setDragging] = useState(false);
  let [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  let onMouseDown = useCallback(
    ({ clientX, clientY }) => {
      setDragging(true);
      setDragStart({ x: clientX, y: clientY });
      console.log("gggiii");
    },
    [dragging, dragStart]
  );

  let onMouseUp = useCallback(() => {
    setDragging(false);
  }, [dragging]);

  useEffect(() => {
    let handler = ({ clientX, clientY }) => {
      if (dragging) {
        setPos({
          top: pos.top + clientY - dragStart.y,
          left: pos.left + clientX - dragStart.x,
        });
        setDragStart({ x: clientX, y: clientY });
      }
    };
    document.addEventListener("mousemove", handler);
    return () => document.removeEventListener('mousemove', handler);
  }, [dragging, pos, dragStart]);

  return (
    <div
      style={{
        position: "absolute",
        top: `${pos.top}px`,
        left: `${pos.left}px`,
        boxShadow: "0px 2px 5px #cccccc",
        zIndex: 2,
        textAlign: "center",

      }}
    >
      <div
        style={{
          width: "100%",
          height: "34px",
          backgroundColor: "#161616",
          color: "white",
          fontFamily: "KrungThep",
          paddingTop: "12px",
          "border-top-left-radius": "10px",
          "border-top-right-radius": "10px"
        }}
        className="window-title"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        {title}
      </div>
      {inner}
    </div>
  );
}
