import { DataTexture, NearestFilter, LuminanceFormat } from "three";

export const monoGradient = (steps = 3) => {
  const colors = Array.from({ length: steps }, (_, i) => (256 * i) / steps);
  const gradientMap = new DataTexture(
    new Uint8Array(colors),
    colors.length,
    1,
    LuminanceFormat
  );
  gradientMap.minFilter = NearestFilter;
  gradientMap.magFilter = NearestFilter;
  gradientMap.generateMipmaps = false;
  return gradientMap;
};
