import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { imageClassifier } from "ml5";

import { massagePrediction } from "./ModelChat"
import { Cloud } from "./CloudUI"


function useDoodleClassifier() {
  const [doodleClassifier, setDoodleClassifier] = useState<ReturnType <imageClassifier>>();

  useEffect(() => {
    let doodlenet = imageClassifier("DoodleNet", () =>
      setDoodleClassifier(doodlenet)
    );
  }, []);
  return doodleClassifier
}


export function DrawingCanvas({ setPredictions, setModelPath, modelPath }) {
  const ref = useRef<HTMLCanvasElement>(null);

  let [predClass, setPredClass] = useState<string>()

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas?.getContext("2d");
    if (context == null) {
      return;
    }

    //Our first draw
    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.lineWidth = 18;
    context.lineJoin = "round";
    context.lineCap = "round";
  }, [ref.current]);

  const doodleClassifier = useDoodleClassifier()

  useEffect(() => {
    const canvas = ref.current;
    if (canvas == null || doodleClassifier == undefined) {
      return;
    }
    let timeout = setInterval(
      () =>
        doodleClassifier!.classify(canvas, (err, results) => {
          if(results == undefined) return
          let mp = massagePrediction(results)
          setPredictions(mp);
          if(mp[0] && mp[0].confidence > 0.05) {
            setPredClass((prev) => {
              if(prev == mp[0].label) return mp[0].label
              else {
                setModelPath(undefined)
                return mp[0].label
              }
            })
          }
        }),
      300
    );
    return () => clearInterval(timeout);
  }, [ref.current, doodleClassifier, setPredictions]);

  let [penDown, setPenDown] = useState(false);

  let onMouseDown = useCallback(
    (e) => {
      setPenDown(true);

      const canvas = ref.current;
      const context = canvas?.getContext("2d");
      if (context == null) {
        return;
      }

      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left; //x position within the element.
      let y = e.clientY - rect.top; //y position within the element.

      context.beginPath();
      context.moveTo(x, y);
    },
    [penDown, ref]
  );

  let onMouseUp = useCallback(() => {
    if (!penDown) {
      return;
    }

    setPenDown(false);
  }, [penDown]);

  let onMouseMove = useCallback(
    (e) => {
      if (!penDown) {
        return;
      }
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left; //x position within the element.
      let y = e.clientY - rect.top; //y position within the element.

      const canvas = ref.current;
      const context = canvas?.getContext("2d");
      if (context == null) {
        return;
      }
      context.lineTo(x, y);
      context.stroke();
    },
    [penDown]
  );

  return (
    <div style={{ maxWidth: "420px" }}>
      <canvas
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        width="420px"
        height="420px"
        ref={ref}
        style={{
          "border-bottom-right-radius": "10px",
          "border-bottom-left-radius": "10px"
        }}
      />
      <button
        className="hbutton"
        onClick={() => {
          const canvas = ref.current;
          const context = canvas?.getContext("2d");
          if (context == null) {
            return;
          }
          context.fillStyle = "#ffffff";
          context.fillRect(0, 0, context.canvas.width, context.canvas.height);
          setPredClass(undefined)
          setModelPath(undefined)
        }}
        style={{
          position: "absolute",
          top: "80%",
          left: "77%",
          height: 0,
          padding: 0,
        }}
      >
        <img src="assets/clear button no drop shadow.svg" alt="clear"></img>
        
      </button>
      {predClass != undefined && modelPath == undefined ? 
      <Cloud predClass={predClass} setModelPath={setModelPath} />
      : <div></div>}
    </div>
  );
}
