import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  Suspense,
  Fragment,
  useLayoutEffect,
} from "react";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";
import {
  MathUtils,
  Vector3,
  Euler,
  Matrix4,
  DynamicDrawUsage,
  BufferGeometry,
  BufferAttribute,
  WireframeGeometry,
  LineSegments,
  EdgesGeometry,
  LineBasicMaterial,
} from "three";
import { Line } from "@react-three/drei";
import { MeshBVH } from "three-mesh-bvh";

import pointInPolygon from "point-in-polygon";

import useKeyState1 from "use-key-state";
const useKeyState = useKeyState1.useKeyState;

import { Outline, EffectComposer, SMAA } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { lassoMesh } from "./tree";
import { useLapDeform, useSimpleDeform } from "./deform";
import { monoGradient } from "./gradientMap";
import { exportBinary } from "./export";

let z = 0;
let fov = 75 / 25.0;

let scale = 0.02;

let position = new Vector3(0.0, -2.0, -100.0);

const useEffectΔₜ = (effect, deps, minΔ = 0) => {
  let prevₜ = useRef(0.0);

  useEffect(() => {
    if (Date.now() - prevₜ.current > minΔ) {
      prevₜ.current = Date.now();
      effect();
    }
  }, deps);
};

export const colorForModel = (path: string) => {
  let s = path.split(".")[0];
  return {
    "1": "orange",
    "2": 0x414cff,
    "3": 0xfa2c98,
  }[s[s.length - 1]];
};

export const Model = ({
  modelRef,
  url,
  lasso,
  delta,
  mode,
  rotation,
}): JSX.Element => {
  const origeom = useLoader(STLLoader, url) as BufferGeometry;

  const geom = useMemo(() => {
    if (modelRef.current?.geometry) return modelRef.current?.geometry;
    let m = BufferGeometryUtils.mergeVertices(origeom, 2.0);
    (m.getAttribute("position") as BufferAttribute).setUsage(DynamicDrawUsage);
    return m;
  }, [origeom, modelRef.current?.geometry]);

  let [highlight, setHighlight] = useState<BufferGeometry>();
  let [selected, setSelected] = useState<ReturnType<typeof lassoMesh>>();

  let { selectVerts, moveVerts } = useSimpleDeform(geom);

  useEffectΔₜ(
    () => {
      if (mode != "lasso") return;
      if (lasso.length < 2) return;
      if (modelRef.current == undefined) return;
      let toScreenSpaceMatrix = new Matrix4();
      toScreenSpaceMatrix
        .copy(modelRef.current.matrixWorld)
        .premultiply(camera.matrixWorldInverse)
        .premultiply(camera.projectionMatrix);

      let bvh = new MeshBVH(geom, { lazyGeneration: false });
      let idx = lassoMesh({ geometry: geom }, bvh, lasso, toScreenSpaceMatrix);
      setSelected(idx);
    },
    [geom, modelRef, lasso],
    100
  );

  useEffectΔₜ(
    () => {
      if (mode != "lasso") return;

      if (selected) {
        let { indices, partial, indices$, partial$ } = selected;

        let newgeom = geom.clone();
        let index = geom.index!;
        let nindex = newgeom.index!;
        for (let i = 0, l = indices.length; i < l; i++) {
          const i2 = index.getX(indices[i]);
          nindex.setX(i, i2);
        }
        newgeom.drawRange.count = indices.length;
        nindex.needsUpdate = true;
        setHighlight(newgeom);

        selectVerts(
          [...indices$],
          Array.from({ length: indices$.size }, (_, i) => 1.0)
        );
        return () => newgeom.dispose();
      }
    },
    [selected],
    100
  );

  let { camera } = useThree();

  useLayoutEffect(() => {
    if (lasso.length < 2) return;
    if (modelRef.current == undefined) return;

    if (delta && selected) {
      let toScreenSpaceMatrix = new Matrix4()
        .copy(modelRef.current.matrixWorld)
        .premultiply(camera.matrixWorldInverse)
        .premultiply(camera.projectionMatrix);

      const yScale = Math.tan((MathUtils.DEG2RAD * fov) / 2) * 0.3;

      let deltaS = delta.clone();
      deltaS.x *= 1 / yScale;
      deltaS.y *= (1 / yScale) * (900 / 600);
      moveVerts(deltaS, toScreenSpaceMatrix);
    }
  }, [delta]);
  return (
    <Fragment>
      <group
        scale={scale}
        position={position}
        rotation={new Euler(...rotation)}
      >
        <mesh ref={modelRef} geometry={geom}>
          <meshToonMaterial
            attach="material"
            color={colorForModel(url)}
            gradientMap={monoGradient(5)}
          />
        </mesh>
        {highlight ? (
          <mesh geometry={highlight}>
            <meshBasicMaterial
              attach="material"
              color="#0000FF"
              opacity={0.5}
              transparent={true}
              depthWrite={false}
              renderOrder={1}
            />
          </mesh>
        ) : null}
      </group>
    </Fragment>
  );
};

type LassoPt = {
  screen: readonly [number, number];
  world: readonly [number, number, number];
};

function yscale(x, y, z) {
  const yScale = Math.tan((MathUtils.DEG2RAD * fov) / 2) * -0.2;
  let wx = x * -yScale * (900 / 600);
  let wy = y * -yScale;
  return [wx, wy];
}

const FX = ({ modelRef }) => (
  <EffectComposer autoClear={false} multisampling={0}>
    <Outline
      selection={modelRef}
      width={900}
      height={600}
      blendFunction={BlendFunction.ALPHA}
      blur={false}
      edgeStrength={5}
      pulseSpeed={0.0}
      xRay={true}
      visibleEdgeColor={0x000000} // the color of visible edges
      hiddenEdgeColor={0x000000}
    />
  </EffectComposer>
);

export function Bg({ modelPath, modelRef }) {
  let [lasso, setLasso] = useState<LassoPt[]>([]);
  let [dragStart, setDragStart] = useState<LassoPt>();
  let [delta, setDelta] = useState<Vector3 | undefined>();
  let [rotation, setRotation] = useState([0, 0, 0]);
  let [dragging, setDragging] = useState(false);
  let [mode, setMode] = useState("lasso");
  let lassoW =
    lasso.length < 2 ? [] : lasso.concat([lasso[0]]).map((l) => l.world);
  let lassoS =
    lasso.length < 2 ? [] : lasso.concat([lasso[0]]).map((l) => l.screen);

  let [history, setHistory] = useState<BufferGeometry[]>([]);

  useEffect(() => {
    if (modelRef.current == undefined) return;

    setHistory([modelRef.current.geometry.clone()]);
    console.log("init history");
  }, [modelRef.current]);

  let { shift, undo } = useKeyState({ shift: "shift", undo: "ctrl+z" });

  if (undo.down && history.length > 1) {
    modelRef.current.geometry = history[history.length - 1];
    setHistory(history.slice(0, history.length - 1));
    console.log("restoring", history);
  }

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      onMouseDown={({ button, nativeEvent, preventDefault }) => {
        let rect = nativeEvent.target.getBoundingClientRect();
        let x = nativeEvent.offsetX / rect.width;
        let y = nativeEvent.offsetY / rect.height;
        x = 2.0 * x - 1.0;
        y = -(2.0 * y - 1.0);
        let [wx, wy] = yscale(x, y, z);
        setDragStart({
          world: [wx, wy, z] as const,
          screen: [x, y] as const,
        });

        if (button == 0 && !shift.pressed) {
          if (lasso.length < 3) {
            setLasso([]);
            setDragging(true);
            setMode("lasso");
            return;
          }

          if (
            pointInPolygon(
              [x, y],
              lasso.map((l) => l.screen)
            )
          ) {
            setDragging(true);
            setMode("move");
            let currGeom = modelRef.current.geometry.clone();
            setHistory(history.concat([currGeom]));
            console.log("saving", currGeom, history);
          } else {
            setLasso([]);
            setDragging(true);
            setMode("lasso");
          }
        } else if (button == 1 || shift.pressed) {
          setDragging(true);
          setMode("rotate");
          return false;
        }
      }}
      onMouseUp={() => {
        setDragging(false);
        if (mode == "move") {
        }
      }}
      onMouseMove={({ nativeEvent }) => {
        if (!dragging) {
          return;
        }
        let rect = nativeEvent.target.getBoundingClientRect();
        let x = nativeEvent.offsetX / rect.width;
        let y = nativeEvent.offsetY / rect.height;
        x = 2.0 * x - 1.0;
        y = -(2.0 * y - 1.0);
        let [wx, wy] = yscale(x, y, z);
        let dx = x - dragStart.screen[0];
        let dy = y - dragStart.screen[1];
        // if(len < 0.01) return

        if (mode == "lasso") {
          if (lasso.length > 1) {
            let p1 = lasso[lasso.length - 1];
            let len = Math.sqrt(dx * dx + dy * dy);
            if (len < 0.01) return;
            if (lasso.length >= 2) {
              let p2 = lasso[lasso.length - 2];
              let prevDx = p1.screen[0] - p2.screen[0];
              let prevDy = p1.screen[1] - p2.screen[1];

              let dx = x - p1.screen[0];
              let dy = y - p1.screen[1];

              let prevLen = Math.sqrt(prevDx * prevDx + prevDy * prevDy);
              let aligness = (dx * prevDx + dy * prevDy) / (len * prevLen);
              if (prevLen < 0.01 || aligness > 0.99) {
                setLasso(
                  lasso.slice(0, lasso.length - 1).concat([
                    {
                      world: [wx, wy, z] as const,
                      screen: [x, y] as const,
                    },
                  ])
                );
                return;
              }
            }
          }
          setLasso(
            lasso.concat([
              {
                world: [wx, wy, z] as const,
                screen: [x, y] as const,
              },
            ])
          );
          return;
        } else if (mode == "move") {
          let nx = lasso[0].screen[0] + dx;
          let ny = lasso[0].screen[1] + dy;
          let [nwx, nwy] = yscale(nx, ny, z);
          setDelta(
            new Vector3(nwx - lasso[0].world[0], nwy - lasso[0].world[1], 0)
          );

          setLasso(
            lasso.map((l) => {
              let nx = l.screen[0] + dx;
              let ny = l.screen[1] + dy;
              let [nwx, nwy] = yscale(nx, ny, z);
              return {
                screen: [nx, ny],
                world: [nwx, nwy, z],
              };
            })
          );

          setDragStart({
            world: [wx, wy, z] as const,
            screen: [x, y] as const,
          });
        } else if (mode == "rotate") {
          setRotation([
            rotation[0] + -dy * 0.05,
            rotation[1] + -dx * 0.1,
            rotation[2],
          ]);
        }
      }}
    >
      <Canvas
        // orthographic
        style={{
          "border-bottom-right-radius": "10px",
          "border-bottom-left-radius": "10px"
        }}

        gl={{
          logarithmicDepthBuffer: true,
          powerPreference: "high-performance",
          antialias: false,
          // stencil: zv,
          // depth: false,
        }}
        camera={{
          fov: fov,
          near: 0.01,
          far: 1000,
          position: [0, 0, 0],

          // left: -1,
          // right: 1,
          // top: 1,
          // bottom: -1
        }}
        onContextMenu={() => false}
      >
        <ambientLight intensity={0.01} />
        <pointLight position={[10, 10, -90]} />
        <Suspense fallback={null}>
          <FX modelRef={modelRef} />

          <Model
            modelRef={modelRef}
            url={modelPath}
            lasso={lassoS}
            delta={delta}
            mode={mode}
            rotation={rotation}
          />
        </Suspense>
        {lasso.length > 2 ? (
          <Line
            points={lassoW.map(([x, y, z]) => [x / 0.002, y / 0.002, -99])} // Array of points
            color="black" // Default
            lineWidth={2} // In pixels (default)
            dashSize={0.1}
            gapSize={0.1}
            dashed={!(mode == "lasso")} // Default
            // vertexColors={[
            //   [0, 0, 0],
            //   [0, 0, 0],
            // ]}
            // {...lineProps}                  // All THREE.Line2 props are valid
            // {...materialProps}              // All THREE.LineMaterial props are valid
            renderOrder={5}
            depthTest={false}
          />
        ) : null}
      </Canvas>
      <button
        className="hbutton"
        onClick={() =>
          exportBinary({
            traverse: (fn) => {
              fn(modelRef.current);
            },
          })
        }
        style={{
          position: "absolute",
          top: "85%",
          left: "90%",
          height: 0,
          padding: 0,
        }}
      >
        <img src="assets/export button.svg" alt="export"></img>
      </button>
    </div>
  );
}
