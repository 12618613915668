import React, { FC, Fragment, useReducer, useRef, useState } from "react";

import { DraggableWindow } from "./containers/DraggableWindow";
import { DrawingCanvas } from "./containers/DrawingCanvas";
import { Bg } from "./containers/Bg";
import { ModelChat } from "./containers/ModelChat";

export function App() {
  let modelRef = useRef();

  let [predictions, setPredictions] = useState([]);
  let [modelPath, setModelPath] = useState();

  let [helpIsOpen, setHelpIsOpen] = useState(false);
  let [aboutIsOpen, setAboutIsOpen] = useState(false);

  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: "35px",
          backgroundColor: "#161616",
          fontFamily: "Krungthep",
          color: "white",
        }}
      >
        <button onClick={()=> (document.location.href = document.location.href)}>new</button>
        <button onClick={() => setHelpIsOpen((h) => !h)}>help</button>
        <button
          style={{ float: "right" }}
          onClick={() => setAboutIsOpen((h) => !h)}
        >
          about
        </button>
        {helpIsOpen ? (
          <div
            style={{
              background: "black",
              maxWidth: 400,
              left: 50,
              position: "absolute",
              padding: "0 20 20 20",
              zIndex: 10,
            }}
          >
            <button
              style={{ float: "right" }}
              onClick={() => setHelpIsOpen(false)}
            >
              x
            </button>
            <p>
              <b>How to use Squiggle Sculpt</b>: Make a doodle in the Squiggle
              Pad (maybe try a cat ^-^ ). Once it's recognized, you should be
              able to choose from a selection of models, which will show up in
              the Squiggle Sculptor. In the Squiggle Sculptor, click + drag lets
              you lasso different areas of the model to move. shift + drag lets
              you rotate the object in space. ctrl + z can be used to undo.z
            </p>
          </div>
        ) : undefined}
        {aboutIsOpen ? (
          <div
            style={{
              background: "black",
              maxWidth: 400,
              right: 0,
              position: "absolute",
              padding: "0 20 20 20",
              zIndex: 10,
            }}
          >
            <button
              style={{ float: "right" }}
              onClick={() => setAboutIsOpen(false)}
            >
              x
            </button>
            <p>
              Welcome to Squiggle Sculpt! <br />
              Squiggle Sculpt is a tool made by Jenny and the Hole Foundation
              designed to recognize your doodles and turn them into modifiable
              3D files. To get started, make a doodle in the Squiggle Pad!
            </p>
          </div>
        ) : undefined}
      </div>
      {modelPath !== undefined ? (
        <DraggableWindow
          top={104}
          left={620}
          title={
            <div
              style={{
                width: "900px",
                height: "25px",
                backgroundColor: "#161616",
                color: "white",
                textAlign: "center",
                zIndex: -99,
              }}
            >
              <span>squiggle sculptor</span>
            </div>
          }
          inner={
            <div
              style={{
                width: "900px",
                height: "600px",
                position: "absolute",
                backgroundImage: 'url("assets/background tile.png")',
                backgroundColor: "#d9caff",
                backgroundSize: "300px",
              }}
            >
              <Bg modelRef={modelRef} modelPath={modelPath} />
            </div>
          }
        ></DraggableWindow>
      ) : undefined}
      <DraggableWindow
        top={222}
        left={225}
        inner={
          <DrawingCanvas
            modelPath={modelPath}
            setModelPath={setModelPath}
            setPredictions={setPredictions}
          />
        }
        title={<span>squiggle pad</span>}
      />
      <ModelChat predictions={predictions}></ModelChat>
    </Fragment>
  );
}
