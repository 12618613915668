import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
} from "react";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

import { monoGradient } from "./gradientMap";
import { colorForModel } from "./Bg";

function SpinnyModel({ geometry, path }) {
  let ref = useRef();
  useFrame((state, delta) => {
    let m = ref.current;
    if (m == undefined) {
      return;
    }
    m.rotation.y += 0.01;
  });
  //   console.log(geom)

  return (
    <mesh
      ref={ref}
      scale={0.02}
      position={[0.0, -3.0, -1.0]}
      geometry={geometry}
    >
      <meshToonMaterial
        attach="material"
        color={colorForModel(path)}
        gradientMap={monoGradient(5)}
        // roughness={1}
        // metalness={0.0}
      />

      {/* <meshStandardMaterial
        attach="material"
        color={colorForModel(path)}
        roughness={1}
        metalness={0.0}
      /> */}
    </mesh>
  );
}

function ModelPreview({ path, setModelPath, top, left }) {
  // debugger
  const geom = useLoader(STLLoader, path);
  return (
    <div onClick={() => setModelPath(path)}>
      <Canvas
        style={{
          position: "absolute",
          width: "400px",
          height: "300px",
          top: top,
          left: left,
        }}
      >
        <ambientLight intensity={0.1} />
        <pointLight position={[10, 10, 10]} />
        <SpinnyModel geometry={geom} path={path}></SpinnyModel>
      </Canvas>
    </div>
  );
}

export function Cloud({ setModelPath, predClass }) {
  return (
    <div style={{ position: "absolute", top: -135, left: 420 }}>
      <img src="assets/cloud revised.svg"></img>
      <Suspense fallback={"loading..."}>
        <ModelPreview
          top={250}
          left={90}
          setModelPath={setModelPath}
          path={`assets/3d files/${predClass}/${predClass}1.stl`}
        ></ModelPreview>
      </Suspense>
      <Suspense fallback={"loading..."}>
        <ModelPreview
          top={113}
          left={239}
          setModelPath={setModelPath}
          path={`assets/3d files/${predClass}/${predClass}2.stl`}
        ></ModelPreview>
      </Suspense>
      <Suspense fallback={"loading..."}>
        <ModelPreview
          top={153}
          left={500}
          setModelPath={setModelPath}
          path={`assets/3d files/${predClass}/${predClass}3.stl`}
        ></ModelPreview>
      </Suspense>
    </div>
  );
}
